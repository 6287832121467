/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');
        ///
        // Navigation Dropdown
        ///
        //
        // Navigation Dropdown
        $('.js-toggle-dropdown').click(function() {
            $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
        });

        // Navigation
        $('.c-header__toggle--htx').click(function(e) {
            e.preventDefault();

            $('.c-header__toggle').toggleClass('is-open');
            $(".c-header__mobile").slideToggle();

            $('.c-header__mobile').toggleClass('is-open');
        });
        ///
        // END Navigation Dropdown
        ///


        //
        //Home preview image left
        //
        var colDesktop2 = $('.c-preview__image--left').data('desktop'),
          colMobile2 = $('.c-preview__image--left').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-preview__image--left').css('background-image', 'url('+colMobile2+')');
        }else{
            $('.c-preview__image--left').css('background-image', 'url('+colDesktop2+')');
        }

        //
        //Home preview image right
        //
        var colDesktop3 = $('.c-preview__image--right').data('desktop'),
          colMobile3 = $('.c-preview__image--right').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-preview__image--right').css('background-image', 'url('+colMobile3+')');
        }else{
            $('.c-preview__image--right').css('background-image', 'url('+colDesktop3+')');
        }



        //
        //Home Offers image left
        //
        var colDesktop4 = $('.c-offers__image--left').data('desktop'),
          colMobile4 = $('.c-offers__image--left').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-offers__image--left').css('background-image', 'url('+colMobile4+')');
        }else{
            $('.c-offers__image--left').css('background-image', 'url('+colDesktop4+')');
        }

        //
        //Home Offers image right
        //
        var colDesktop5 = $('.c-offers__image--right').data('desktop'),
          colMobile5 = $('.c-offers__image--right').data('mobile');
        if(viewportWidth < 1024 ){
            $('.c-offers__image--right').css('background-image', 'url('+colMobile5+')');
        }else{
            $('.c-offers__image--right').css('background-image', 'url('+colDesktop5+')');
        }

        if ($('.js-property-gallery').length) {
          $('.js-property-gallery').slick({
            arrows: true,
            asNavFor: '.js-property-gallery-nav',
            dots: false,
            fade: true,
            nextArrow: '<button type="button" class="slick-prev" aria-label="Previous Gallery Item"><i class="fas fa-chevron-left"></i></button>',
            prevArrow: '<button type="button" class="slick-next" aria-label="Next Gallery Item"><i class="fas fa-chevron-right"></i></button>',
            rows: 0,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if ($('.js-property-gallery-nav').length) {
          $('.js-property-gallery-nav').slick({
            arrows: false,
            asNavFor: '.js-property-gallery',
            autoplay: true,
            dots: false,
            focusOnSelect: true,
            rows: 0,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
